<template>
  <base-page>
    <router-view :key="$route.fullPath" />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SujetPage',

  meta () {
    return {
      titleTemplate: title => `${title} - Sujet - Comité | AGORA`
    }
  },

  beforeDestroy () {
    this.$store.commit('sujets/setCurrentSujet', null)
  },

  computed: {
    ...mapGetters({
      sujet: 'sujets/getCurrentSujet'
    })
  },

  watch: {
    sujet (newVal, oldVal) {
      this.$store.commit('pages/setPageTitle', newVal.label)
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('sujets.sujets_list'), to: { name: 'sujets-list' } },
      {}
    ])
  }
}
</script>
